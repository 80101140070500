import React, { FC } from "react";
import styled from 'styled-components';
import Container from '../components/container';
import { Description, H3 } from "../globalStyling";
import Button from "../components/button"

const Uitdaging: FC = () => {
    return (
        <Container id="uitdaging">
            <UitdagingContainer>
                <UitdagingImage src={require('../assets/img/uitdaging.png')} />
                <UitdagingContent>
                    <H3>Ook direct met 1-0 voor staan?</H3>
                    <Description marginMobile="0 0 16px 0" marginDesktop="0 0 32px 0">
                        Of je nu eenmalig advies nodig hebt of op zoek bent naar intensieve begeleiding: samen winnen we de wedstrijd. Kan Het Sportpunt iets voor jouw organisatie betekenen? Maak dan nu een afspraak.
                    </Description>
                    <Button title="Neem contact op" width="unset" />
                </UitdagingContent>
            </UitdagingContainer>
        </Container>
    )
}

const UitdagingContainer = styled.div`
    display:flex;
    flex-wrap:wrap;
    align-items:center;
    justify-content:space-between;
    margin: 48px 0 56px 0;

    @media screen and (min-width:${props => props.theme.devices.desktop}){
        margin:80px 0 112px 0;
    }
`

const UitdagingImage = styled.img`
    width:100%;
    margin-bottom:24px;

    @media screen and (min-width:${props => props.theme.devices.desktop}){
        margin-bottom:0;
        width:50%;
        order:2;
    }
`

const UitdagingContent = styled.div`
    & h3{
        margin-bottom:8px;
    }

    @media screen and (min-width:${props => props.theme.devices.desktop}){
        order:1;
        width:42%;

        & h3{
            margin-bottom:16px;
        }
    }
`

export default Uitdaging;