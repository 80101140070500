import React, { FC, useState } from 'react';
import Slider from 'react-slick';
import styled from 'styled-components';
import Container from '../components/container';
import { H3, Description } from '../globalStyling';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

interface Project {
    title: string;
    description: string;
    readMore?: boolean;
    readMoreText?: string;
    category: string;
    image?: string;
}

interface TabPanelProps {
    projectsInCat: Project[];
    index: number;
    activeCategory: number;
}

interface ImageProps {
    src: string;
}

const TabPanel: FC<TabPanelProps> = ({
    projectsInCat,
    activeCategory,
    index,
}) => {
    const [readMore, toggleReadMore] = useState(false);
    const handleChange = (event: React.MouseEvent<HTMLAnchorElement>) => {
        toggleReadMore(!readMore);
    };

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        beforeChange: () => toggleReadMore(false),
    };

    return (
        <TabContent
            role="tabpanel"
            hidden={activeCategory !== index}
            aria-labelledby={`simple-tab-${index}`}
        >
            <Slider {...settings}>
                {projectsInCat.map((item, i) => (
                    <ProjectInfo key={i}>
                        <div>
                            <Image
                                src={require(`../assets/img/projects/${item.image}`)}
                            />
                            <H3 marginMobile="0 0 16px 0">{item.title}</H3>
                            <Description marginMobile="0 0 16px 0">
                                {item.description}
                            </Description>
                            {item.readMore && !readMore && (
                                <LeesMeer onClick={handleChange}>
                                    Lees meer
                                </LeesMeer>
                            )}
                            {readMore && (
                                <Description className="read-more-content">
                                    {item.readMoreText}
                                </Description>
                            )}
                        </div>
                    </ProjectInfo>
                ))}
            </Slider>
        </TabContent>
    );
};

const Projecten: FC = () => {
    const [activeCategory, setActiveCategory] = React.useState(0);
    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setActiveCategory(newValue);
    };

    const projects = [
        {
            title: 'Procesbegeleiding Ready2Sport, Atletiekunie',
            description:
                'Voor de Atletiekunie hebben we atletiekclubs enthousiast gemaakt en begeleiden we hen om aanbod te creëren voor mensen met een motorische beperking. Door het lokale aanbod te vergroten en de infrastructuur te verbeteren, zijn atletiekclubs toegankelijker geworden voor iedereen.',
            category: 'Begeleiding & ondersteuning',
            image: 'project_atletiekunie.jpg',
        },
        {
            title: 'Adviseur lokale sport, NOC NSF',
            description:
                'In de gemeentes Renswoude, Soest en Leusden, ondersteunen we sportclubs, vanuit de gezamenlijke sportbonden en NLActief. Als adviseur lokale sport verbinden we hen onderling en met lokale partners, zoals de gemeentes. Dankzij deze verbinding krijgen de clubs makkelijker en sneller ondersteuning in de vorm van services waar zij behoefte aan hebben. Denk hierbij aan trainersopleidingen, masterclasses voor het bestuur, verenigingstrajecten en nog veel meer.',
            category: 'Beleid',
            image: 'project_adviseur_lokale_sport.jpg',
        },
        {
            title:
                'Projectleiding en clubaccountmanagement, Judo Bond Nederland',
            description:
                'We ondersteunen Judo Bond Nederland op wekelijkse basis en bij allerlei andere activiteiten. Zo zijn we actief als clubaccountmanager in 3 districten (Noord, Oost en Midden Nederland) en voeren 2 verschillende projecten uit: de JBN Randori Competitie en Zeker Bewegen.',
            readMore: true,
            readMoreText:
                'Allereerst helpen we als clubaccountmanager bij het binden van leden; we maken de diensten zichtbaarder voor judoclubs en -leden en ondersteunen hen op allerlei gebieden. Daarnaast zijn we projectleider van de JBN Randori competitie: een competitie speciaal voor beginnende en recreatieve judoka’s. Ook zijn we partner binnen het project Zeker Bewegen: het valpreventie programma van de Judo Bond.',
            category: 'Management',
            image: 'project_JBN.jpg',
        },
        {
            title: 'Partner, Back2Basics beweging',
            description:
                'Het Sportpunt maakt deel uit van netwerkorganisatie Back2Basics. Onze missie? De wereld verenigen. Dagelijks streven wij ernaar om professionals, teams, organisaties en bedrijven te ondersteunen en de verenigkracht te vergroten. Door jarenlange ervaring met verenigen binnen allerlei soorten organisaties hebben wij geleerd wat wel en niet werkt.',
            readMore: true,
            readMoreText:
                'Maar niet alleen bij sportorganisaties, ook voor maatschappelijke organisaties en het bedrijfsleven waar het gedachtegoed en de sportmetafoor goed aanslaat. Dit doen we, binnen Back2Basics, met een bevlogen team van zo’n 30 professionals die het gedachtegoed omarmen. Samen empoweren wij organisaties om de filosofie eigen te maken.',
            category: 'Organisatie',
            image: 'project_Back2Basiscs.jpg',
        },
        {
            title: 'Sportformateur Lokaal Sportakkoord, Gemeente Leusden',
            description:
                'Als sportformateur brachten we diverse partijen binnen de Gemeente Leusden aan tafel om te praten over de invulling van het sportakkoord. Het Sportpunt organiseerde en faciliteerde de totstandkoming van het lokale sport- en beweegakkoord. We hebben hiermee het lokale en regionale netwerk verstrekt rondom sport en bewegen en allerlei partijen geïnspireerd om ermee aan de slag te gaan. ',
            category: 'Beleid',
            image: 'project_sportformateur.jpg',
        },
        {
            title: 'Programmamanagement Buurtsportteams, Zeewolde en Nijkerk',
            description:
                'In dit project was Het Sportpunt verantwoordelijk voor het behalen van de projectresultaten, programmadoelen en voor de sturing van projecten en programma’s van buurtsportteams in Nijkerk en Zeewolde. Ook zorgden we voor de planning van de activiteiten en stuurden we de buurtsportcoaches aan.',
            readMore: true,
            readMoreText:
                'De buurtsportteams zijn actief op het gebied van sport en gezondheid en stimuleren een gezonde leefstijl. Door het organiseren van activiteiten voor verschillende doelgroepen, gaven we uitvoering aan de sport- en gezondheidsvisie van de gemeentes Zeewolde en Nijkerk. Een van deze activiteiten was JOGG: Jongeren op gezond gewicht. Door organisaties zoals scholen, sportclubs, huisartsen, buurtsupers en woningbouwverenigingen met elkaar te verbinden, creëren we meer aandacht voor voeding en beweging onder jongeren. Zo zorgen we samen dat gezond eten en drinken, dagelijks bewegen en sporten een vanzelfsprekendheid wordt.',
            category: 'Management',
            image: 'project_buurtsportteams.jpg',
        },
    ];

    const categories = [
        'Management',
        'Begeleiding & ondersteuning',
        'Beleid',
        'Organisatie',
    ];

    return (
        <Container id="projecten">
            <ProjectContainer>
                <div>
                    <H3 marginMobile="0 0 24px 0" textAlign="center">
                        Onze projecten
                    </H3>

                    <CategoryTabs
                        value={activeCategory}
                        indicatorColor="primary"
                        textColor="primary"
                        onChange={handleChange}
                    >
                        {categories.map((cat, i) => (
                            <Tab label={cat} key={i} />
                        ))}
                    </CategoryTabs>
                    {categories.map((cat, i) => (
                        <TabPanel
                            key={i}
                            projectsInCat={projects.filter(
                                (project) => project.category === categories[i]
                            )}
                            index={i}
                            activeCategory={activeCategory}
                        />
                    ))}
                </div>
            </ProjectContainer>
        </Container>
    );
};

const ProjectContainer = styled.div`
    display: flex;
    justify-content: center;
    margin-bottom: 56px;
    margin-top: 48px;

    & > div {
        max-width: 1166px;
        width: 100%;
    }

    @media screen and (min-width: ${(props) => props.theme.devices.desktop}) {
        margin-top: 94px;
        margin-bottom: 112px;
    }
`;

const CategoryTabs = styled(Tabs)`
    & .MuiTab-root {
        font-size: ${(props) => props.theme.fontSizes.medium};
        font-family: ${(props) => props.theme.fontFamily.primary};
        line-height: ${(props) => props.theme.lineHeight.medium};
        letter-spacing: normal;
        padding: 0;
        min-width: 0;
        margin-right: 8px;
        margin-left: 8px;
        color: ${(props) => props.theme.colors.black2};
        max-width: unset;
        text-transform: unset;
        font-weight: ${(props) => props.theme.fontWeights.regular};
    }
    & .MuiTabs-flexContainer {
        @media screen and (min-width: ${(props) =>
                props.theme.devices.tablet}) {
            justify-content: center;
        }
    }
    & .MuiButtonBase-root.Mui-selected {
        color: ${(props) => props.theme.colors.black2};
        font-weight: ${(props) => props.theme.fontWeights.bold};
    }
    & .MuiTabs-indicator {
        background-color: ${(props) => props.theme.colors.orange};
    }
    & * {
        overflow-x: auto;
    }
`;

const TabContent = styled.div`
    margin-top: 24px;

    & .slick-arrow {
        top: 100px;
    }

    & .slick-slide:not(.slick-current) .read-more-content {
        display: none;
    }
    @media screen and (min-width: ${(props) => props.theme.devices.tablet}) {
        & .slick-arrow {
            top: 150px;
        }
    }
    @media screen and (min-width: ${(props) => props.theme.devices.laptop}) {
        & .slick-arrow {
            top: 200px;
        }
    }

    @media screen and (min-width: ${(props) => props.theme.devices.desktop}) {
        margin-top: 32px;

        & .slick-arrow {
            top: 300px;
        }
    }
`;
const ProjectInfo = styled.div`
    overflow: hidden;

    & :focus {
        outline: unset;
    }
`;

const Image = styled.div<ImageProps>`
    background-size: cover;
    background-position: center;
    background-image: url(${(props) => props.src});
    height: 200px;
    width: 100%;
    margin: 0 auto 24px auto;

    @media screen and (min-width: ${(props) => props.theme.devices.tablet}) {
        height: 300px;
    }

    @media screen and (min-width: ${(props) => props.theme.devices.laptop}) {
        height: 400px;
    }

    @media screen and (min-width: ${(props) => props.theme.devices.desktop}) {
        height: 600px;
        margin-bottom: 56px;
    }
`;

const LeesMeer = styled.a`
    color: ${(props) => props.theme.colors.black2};
    cursor: pointer;

    & ::after {
        content: url(${require('../assets/icons/lees_meer.svg')});
        margin-left: 8px;
    }
`;

export default Projecten;
