import React, { FC } from 'react';
import styled from 'styled-components';
import Container from '../components/container';
import BackgroundImage from '../components/backgroundImage';
import { H4, SocialMediaLinks, MenuItemFB, MenuItemLI } from '../globalStyling';
import { theme } from '../constants/theme';

interface ContactListItem {
    icon?: string;
    iconTop?: string;
}

const Contact: FC = () => (
    <ContactSectionColor id="contact">
        <BackgroundImage />
        <Container>
            <ContactContainer>
                <ContactImageContainer>
                    <ContactImage />
                </ContactImageContainer>
                <ContactContentContainer>
                    <ContactContent>
                        <ul>
                            <PersonInfo
                                iconTop="4px"
                                icon={require(`../assets/icons/location.svg`)}
                            >
                                <H4>Het Sportpunt</H4>
                                <H4
                                    marginMobile="0 0 8px 0"
                                    marginDesktop="0 0 16px 0"
                                >
                                    Edwin Korenberg
                                </H4>
                                <Adress>Visotter 28</Adress>
                                <Adress>3831 DZ Leusden</Adress>
                            </PersonInfo>
                            <PersonInfo
                                iconTop="2px"
                                icon={require(`../assets/icons/phone.svg`)}
                            >
                                <PhoneLink href="tel:+31650258960">
                                    06 50258960
                                </PhoneLink>
                            </PersonInfo>
                            <PersonInfo
                                iconTop="0"
                                icon={require(`../assets/icons/mail.svg`)}
                            >
                                <PhoneLink href="mailto:info@hetsportpunt.nl">
                                    info@hetsportpunt.nl
                                </PhoneLink>
                            </PersonInfo>
                            <PersonInfo>
                                <SocialMediaLinks>
                                    <a
                                        className="SM-link"
                                        href="https://www.facebook.com/HetSportpunt/"
                                    >
                                        <MenuItemFB
                                            color={theme.colors.black2}
                                        />
                                    </a>
                                    <a
                                        className="SM-link"
                                        href="https://www.linkedin.com/in/edwin-korenberg-34b1b617/ "
                                    >
                                        <MenuItemLI
                                            color={theme.colors.black2}
                                        />
                                    </a>
                                </SocialMediaLinks>
                            </PersonInfo>
                        </ul>
                    </ContactContent>
                </ContactContentContainer>
            </ContactContainer>
        </Container>
    </ContactSectionColor>
);

const ContactSectionColor = styled.div`
    padding: 32px 0;
    background-color: ${({ theme }) => theme.colors.black2};
    position: relative;
    overflow: hidden;

    & * {
        color: ${({ theme }) => theme.colors.white};
    }

    & .SM-link svg {
        margin: 8px 24px 0 0;
    }

    @media screen and (min-width: ${(props) => props.theme.devices.laptop}) {
        padding: 104px 0;
    }
`;

const ContactContainer = styled.div`
    max-width: 1166px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    @media screen and (max-width: 767px) {
        flex-direction: column;
        align-items: center;
    }
`;

const ContactImageContainer = styled.div`
    min-width: 50%;
    display: flex;
    justify-content: flex-end;
`;
const ContactContentContainer = styled.div`
    min-width: 50%;
`;

const ContactImage = styled.div`
    background-image: url(${require(`../assets/img/contact.png`)});
    background-size: cover;
    background-position: center;
    min-width: 260px;
    max-width: 400px;
    width: 100%;
    height: 200px;
    margin-bottom: 16px;
    @media screen and (min-width: 768px) {
        margin-right: 16px;
        margin-bottom: 0;
        height: 100%;
    }
`;

const ContactContent = styled.div`
    @media screen and (min-width: 768px) {
        margin-left: 16px;
    }
`;

const Adress = styled.div`
    font-size: 16px;
    line-height: 24px;
    font-family: ${({ theme }) => theme.fontFamily.secondary};
`;

const PersonInfo = styled.li<ContactListItem>`
    padding-left: 40px;
    list-style-type: none;
    margin-bottom: 16px;
    position: relative;

    & ::before {
        content: '';
        position: absolute;
        left: 0;
        top: ${({ iconTop }) => iconTop};
        width: 24px;
        height: 24px;
        background-image: url(${({ icon }) => icon});
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
    }

    & :last-child {
        margin-bottom: 0;
    }

    @media screen and (min-width: ${(props) => props.theme.devices.laptop}) {
        margin-bottom: 24px;
    }
`;

const PhoneLink = styled.a`
    font-family: ${({ theme }) => theme.fontFamily.secondary};
`;

export default Contact;
