import React, { FC } from 'react';
import styled from 'styled-components';
import Container from '../components/container';
import { H3 } from '../globalStyling';

interface PartnerImageProps {
    imgName: string;
}

const Samenwerkingen: FC = () => {
    return (
        <Container id="samenwerkingen">
            <H3
                marginMobile="0 0 16px 0"
                marginDesktop="0 0 24px 0"
                textAlign="center"
            >
                Zij gingen je voor
            </H3>
            <PartnerContainer>
                <PartnerImage imgName="leusden" />
                <PartnerImage imgName="nocnsf" />
                <PartnerImage imgName="JBN" />
                <PartnerImage imgName="Atletiekunie" />
                <PartnerImage imgName="gemeente-zeewolde-1" />
                <PartnerImage imgName="owios" />
                <PartnerImage imgName="sportimpuls" />
                <PartnerImage imgName="logobongerd" />
                <PartnerImage imgName="larikslaan" />
                <PartnerImage imgName="Hamershof2_0" />
                <PartnerImage imgName="NIVM" />
            </PartnerContainer>
        </Container>
    );
};

const PartnerImage: FC<PartnerImageProps> = ({ imgName }) => {
    return (
        <PartnerImageContainer>
            <PartnerImg
                src={require(`../assets/img/partners/${imgName}.png`)}
            />
        </PartnerImageContainer>
    );
};

const PartnerContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    max-width: 961px;
    margin-left: auto;
    margin-right: auto;
`;

const PartnerImageContainer = styled.div`
    margin: 8px 12px;
    @media screen and (min-width: ${(props) => props.theme.devices.desktop}) {
        margin: 16px;
    }
`;

const PartnerImg = styled.img`
    max-width: 93px;
    max-height: 60px;

    @media screen and (min-width: ${(props) => props.theme.devices.desktop}) {
        max-width: 200px;
        max-height: 100px;
    }
`;

export default Samenwerkingen;
