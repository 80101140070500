import React, { FC } from 'react';
import styled from 'styled-components';
import Container from '../components/container';
import { H3, H4, Description } from '../globalStyling';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

interface ImageProps {
    src: string;
}

const Quotes: FC = () => {
    var quotesInfo = [
        {
            quote:
                'Het Sportpunt is betrokken, betrouwbaar, mensgericht en succesvol in het bereiken van resultaten, zeker op clubniveau',
            person: 'Benny van den Broek',
            function: 'Coördinator sportparticipatie JBN',
            image: 'Benny_van_den_Broek.jpg',
        },
        {
            quote:
                'Over de ondersteuning van en samenwerking met Het Sportpunt zijn wij zeer tevreden.',
            person: 'Marita Voogt',
            function: 'Voorzitter Leusder Handbal Vereniging',
            image: 'Marita_Voogt_2.jpg',
        },
        {
            quote:
                'Wij zijn zeer tevreden over de samenwerking en het vele werk dat ons uit handen werd genomen.',
            person: 'Jacko van den Bosch',
            function: 'Voorzitter vv OWIOS',
            image: 'Jacko_van_den_Bosch.jpg',
        },
        {
            quote:
                'Het Sportpunt is gericht op samenwerken en harmonie. Mede door de inspanningen van het Sportpunt ligt er een prima akkoord, gedragen door veel partners.',
            person: 'Henriette Jansen',
            function: 'Beleidsmedewerker Gemeente Leusden',
            image: 'Henriette_Jansen.jpg',
        },
        {
            quote:
                'Dankzij de coördinatie en procesbegeleiding van Het Sportpunt is ons project met buurtsportcoaches goed van de grond gekomen.',
            person: 'Margo Sentjens',
            function: 'Voormalig directeur Welzijn Zeewolde',
            image: 'Margo_Sentjens.jpg',
        },
        {
            quote:
                'De samenwerking met Het Sportpunt was uitstekend. Ze hebben zich ingezet als coördinator van de Vrijwillige Hulpdienst. De zelfstandigheid van de burger is centraal komen te staan en de overheid treedt nu meer faciliterend op.',
            person: 'Henk Bakker',
            function: 'Hoofd Afdeling Samenleving Gemeente Zeewolde',
            image: 'Henk_Bakker.jpg',
        },
    ];

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
    };

    return (
        <Container id="testimonials">
            <QuotesContainer>
                <H3
                    marginMobile="0 0 8px 0"
                    marginDesktop="0 0 16px 0"
                    textAlign="center"
                >
                    Ervaringen met Het Sportpunt
                </H3>
                <Subtitle>
                    Dit zeggen opdrachtgevers over onze samenwerking:
                </Subtitle>
                <QuoteSlider {...settings}>
                    {quotesInfo.map((item, i) => (
                        <QuoteItem key={i}>
                            <QuoteInfo>
                                <Quote>“{item.quote}”</Quote>
                                <div>
                                    <H4 marginMobile="16px 0 0 0">
                                        {item.person}
                                    </H4>
                                    <Function>{item.function}</Function>
                                </div>
                            </QuoteInfo>
                            <PersonImage
                                src={require(`../assets/img/quotes/${item.image}`)}
                            />
                        </QuoteItem>
                    ))}
                </QuoteSlider>
            </QuotesContainer>
        </Container>
    );
};

const QuotesContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
    width: 100%;
    margin-bottom: 56px;

    & > *:not(.slick-slider) {
        max-width: 1166px;
    }

    & > .slick-slider {
        max-width: 1202px;
    }

    & .slick-arrow {
        top: calc(50% - 50px);
    }

    @media screen and (min-width: ${(props) => props.theme.devices.desktop}) {
        margin-bottom: 104px;

        .slick-prev {
            transform: translate(-100%, -50%);
        }

        .slick-next {
            transform: translate(100%, -50%);
        }
    }
`;

const Subtitle = styled.p`
    color: ${({ theme }) => theme.colors.black2};
    font-size: ${({ theme }) => theme.fontSizes.medium};
    line-height: ${({ theme }) => theme.lineHeight.medium};
    text-align: center;
    margin-bottom: 24px;

    @media screen and (min-width: ${(props) => props.theme.devices.desktop}) {
        font-size: ${({ theme }) => theme.fontSizes.header4};
        line-height: ${({ theme }) => theme.lineHeight.large};
        margin-bottom: 40px;
    }
`;

const QuoteSlider = styled(Slider)`
    width: 100%;
    max-width: 1202px;

    .slick-list {
        margin: 0 -16px;
    }

    .slick-dots {
        bottom: 0px;
    }

    @media screen and (min-width: ${(props) => props.theme.devices.desktop}) {
        .slick-list {
            margin: 0;
        }
    }
`;

// Use important because of inline styling of Slider
const QuoteItem = styled.div`
    display: flex !important;
    flex-direction: column;
    width: calc(100% - 32px) !important;
    position: relative;
    margin: 0 16px 80px 16px;
    padding: 24px 24px 92px 24px;
    background-color: ${(props) => props.theme.colors.lightGrey};
    min-height: 500px;

    @media screen and (min-width: 400px) {
        min-height: 380px;
    }

    @media screen and (min-width: ${(props) => props.theme.devices.mobile}) {
        height: 348px;
        margin-bottom: 100px;
    }
    @media screen and (min-width: ${(props) => props.theme.devices.tablet}) {
        height: 316px;
    }
    @media screen and (min-width: ${(props) => props.theme.devices.laptop}) {
        padding: 56px;
        flex-direction: row;
        margin-top: 40px;
        height: 376px;
    }
    @media screen and (min-width: ${(props) => props.theme.devices.desktop}) {
        height: 344px;
    }
`;

const QuoteInfo = styled.div`
    @media screen and (min-width: ${(props) => props.theme.devices.tablet}) {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    @media screen and (min-width: ${(props) => props.theme.devices.laptop}) {
        max-width: 48%;
    }
`;

const Quote = styled.p`
    font-size: ${(props) => props.theme.fontSizes.header4};
    line-height: ${(props) => props.theme.lineHeight.header4};
    color: ${(props) => props.theme.colors.black2};

    overflow-wrap: break-word;
`;

const Function = styled.p`
    color: ${(props) => props.theme.colors.black2};
    font-weight: ${(props) => props.theme.fontWeights.bold};
`;

const PersonImage = styled.div<ImageProps>`
    width: 128px;
    position: absolute;
    bottom: 0;
    right: 24px;
    transform: translateY(27%);
    background-size: cover;
    background-position: center;
    background-image: url(${(props) => props.src});

    & :after {
        content: '';
        display: block;
        padding-bottom: 100%;
    }

    @media screen and (min-width: ${(props) => props.theme.devices.mobile}) {
        width: 175px;
    }

    @media screen and (min-width: ${(props) => props.theme.devices.tablet}) {
        width: 200px;
    }
    @media screen and (min-width: ${(props) => props.theme.devices.laptop}) {
        transform: unset;
        width: calc(48% - 40px);
        right: 40px;
        transform: unset;
        top: -20px;
        bottom: -20px;
    }
    @media screen and (min-width: ${(props) => props.theme.devices.desktop}) {
        width: calc(48% - 80px);
        top: -30px;
        bottom: -30px;
        right: 80px;
    }
    @media screen and (min-width: ${(props) => props.theme.devices.bigScreen}) {
        width: calc(48% - 120px);
        top: -40px;
        bottom: -40px;
        right: 120px;
    }
`;

export default Quotes;
