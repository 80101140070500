import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout"
import SEO from "../components/seo"
import Theme from "../constants/theme";
import Intro from "../sections/intro"
import Usps from "../sections/usps"
import Diensten from "../sections/diensten"
import Uitdaging from "../sections/uitdaging"
import Samenwerkingen from "../sections/samenwerkingen"
import Projecten from "../sections/projecten"
import Quotes from "../sections/quotes"
import Contact from "../sections/contact"
import GlobalStyle from "../globalStyling"

export default function Home() {
  return (
    <>
      <Theme>
        <GlobalStyle />
        <Layout>
          <SEO />
          <Intro />
          <Usps />
          <Diensten />
          <Uitdaging />
          <Samenwerkingen />
          <Projecten />
          <Quotes />
          <Contact />
        </Layout>
      </Theme>
    </>
  )
}