import React, { FC } from 'react';
import styled from 'styled-components';
import Container from '../components/container';

interface uspProps {
    title: string;
    hideMobile?: boolean;
}

interface uspStylingProps {
    hideMobile?: boolean;
}

const Usp: FC<uspProps> = ({ title, hideMobile = false }) => {
    return (
        <UspItem hideMobile={hideMobile}>
            <img
                src={require('../assets/img/usp.png')}
                width="24"
                height="24"
            />
            <p>{title}</p>
        </UspItem>
    );
};

const Usps: FC = () => {
    return (
        <Container>
            <IntroSection>
                <Usp title="Ondersteuning op maat" />
                <Usp title="Persoonlijke begeleiding" />
                <Usp title="Resultaatgericht" />
                <Usp title="15 jaar ervaring" />
                <Usp title="Meer dan 100 tevreden clubs" />
            </IntroSection>
        </Container>
    );
};

const IntroSection = styled.div`
    margin: 40px 0;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;

    @media screen and (min-width: ${(props) => props.theme.devices.desktop}) {
        margin: 32px 0 112px 0;
    }
`;

const UspItem = styled.div<uspStylingProps>`
    margin: 8px 0;
    ${(props) => (props.hideMobile ? 'display:none;' : 'display:flex;')}
    flex-basis:100%;
    justify-content: center;
    font-weight: ${(props) => props.theme.fontWeights.regularBold};

    p {
        font-family: ${(props) => props.theme.fontFamily.secondary};
        color: ${(props) => props.theme.colors.black2};
        margin-left: 8px;
    }

    @media screen and (min-width: ${(props) => props.theme.devices.desktop}) {
        margin: 0 12px;
        flex-basis: unset;
        ${(props) => props.hideMobile && 'display:flex;'}
    }
`;

export default Usps;
