import styled, { css } from 'styled-components';

interface Props {
    mobileCenter?: boolean;
}

const right = `
    left: unset;
    right: -5%;
    bottom: -7%;
    top: unset;
    transform: unset;
    max-height: 100%;
`;

const center = `
    top: 20px;
    left: 50%;
    transform: translateX(-50%);
`;

const getPositionCss = (mobileCenter: boolean) => {
    return mobileCenter
        ? css`
              ${center}
              @media screen and (min-width: ${(props) =>
                  props.theme.devices.desktop}) {
                  ${right}
              }
          `
        : css`
              ${right}
          `;
};

const BackgroundImage = styled.div<Props>`
    background-image: url(${require('../assets/img/intro_netwerk.png')});
    position: absolute;
    width: 55%;
    background-size: cover;
    ${({ mobileCenter }) => getPositionCss(mobileCenter)}

    &:after {
        content: '';
        display: block;
        padding-bottom: 100%;
    }
`;

export default BackgroundImage;
