import React, { FC } from 'react';
import styled from 'styled-components';
import Container from '../components/container';
import BackgroundImage from '../components/BackgroundImage';
import { H1, Description, TextLink, ButtonContainer } from '../globalStyling';
import Button from '../components/button';
import { theme } from '../constants/theme';

const Intro: FC = () => (
    <IntroSection>
        <BackgroundImage mobileCenter />
        <Container id="intro">
            <IntroContent>
                <div>
                    <IntroImage src={require('../assets/img/beelden.png')} />
                </div>
                <IntroText>
                    <H1
                        marginDesktop="0 0 16px 0"
                        marginMobile="24px 0 8px 0"
                        color="#FFFFFF"
                    >
                        Het Sportpunt brengt je in beweging
                    </H1>
                    <Description marginMobile="0 0 24px 0" color="#FFFFFF">
                        Wij verbinden vraag en aanbod op het gebied van sport,
                        gezondheid en welzijn. Met persoonlijke begeleiding en
                        advies op maat, maken we Nederland elke dag een stukje
                        sportiever.
                    </Description>
                    <ButtonContainer>
                        <Button
                            title="Ontdek ons aanbod"
                            arrowDown={true}
                            link="/#diensten"
                            marginMobile="0 0 8px 0"
                            marginDesktop="0 24px 0 0"
                            color={theme.colors.black2}
                            background={theme.colors.white}
                        />
                        <TextLink to="/#contact">
                            Neem direct contact op
                        </TextLink>
                    </ButtonContainer>
                </IntroText>
            </IntroContent>
        </Container>
    </IntroSection>
);

const IntroSection = styled.div`
    background-color: ${(props) => props.theme.colors.black2};
    position: relative;
    overflow: hidden;
    padding: 0 0 40px 0;
    margin-top: 70px;

    @media screen and (min-width: ${(props) => props.theme.devices.desktop}) {
        padding: 116px 0;
    }
`;

const IntroImage = styled.img`
    width: 100%;
    z-index: ${(props) => props.theme.zIndices.overlap};
`;

const IntroContent = styled.div`
    display: flex;
    flex-direction: column;

    @media screen and (min-width: ${(props) => props.theme.devices.desktop}) {
        flex-direction: row-reverse;
        align-items: center;

        > * {
            flex-basis: 50%;
        }
    }
`;

const IntroText = styled.div`
    @media screen and (min-width: ${(props) => props.theme.devices.desktop}) {
        padding-right: 5%;
    }
`;

export default Intro;
