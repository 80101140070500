import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

function SEO({ description, lang, meta, title }) {
    const { site } = useStaticQuery(
        graphql`
            query {
                site {
                    siteMetadata {
                        title
                        description
                        author
                    }
                }
            }
        `
    );

    const metaDescription = description || site.siteMetadata.description;
    const metaTitle = title || site.siteMetadata.title;

    return (
        <Helmet
            title={metaTitle}
            htmlAttributes={{
                lang,
            }}
            titleTemplate={`%s | ${site.siteMetadata.title}`}
        >
            <meta charSet="utf-8" />
            {metaTitle && <meta property="og:title" content={metaTitle} />}
            <meta property="og:type" content="website" />
            {metaDescription && (
                <meta property="og:description" content={metaDescription} />
            )}
            {metaDescription && (
                <meta name="twitter:description" content={metaDescription} />
            )}
            <meta name="twitter:card" content="summary" />
            {site.siteMetadata.author && (
                <meta
                    name="twitter:creator"
                    content={site.siteMetadata.author}
                />
            )}
            {metaTitle && <meta name="twitter:title" content={metaTitle} />}
            {metaDescription && (
                <meta name="description" content={metaDescription} />
            )}
            <title>{metaTitle}</title>
            <link rel="canonical" href="https://www.hetsportpunt.nl" />
        </Helmet>
    );
}

SEO.defaultProps = {
    lang: `nl`,
    meta: [],
    description: ``,
    title:
        'Het Sportpunt | Persoonlijke begeleiding en advies op maat | Het Sportpunt | Persoonlijke begeleiding en advies op maat',
};

SEO.propTypes = {
    description: PropTypes.string,
    lang: PropTypes.string,
    meta: PropTypes.arrayOf(PropTypes.object),
    title: PropTypes.string.isRequired,
};

export default SEO;
