import React, { FC, useState } from 'react';
import styled from 'styled-components';
import Container from '../components/container';
import { StylesProvider } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { H2, H4, Description, MobileOnly, DesktopOnly } from '../globalStyling';

interface DienstenItemProps {
    value: string;
}

const Diensten: FC = () => {
    const [activeProject, setActive] = useState('management');
    const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setActive(event.target.value as string);
    };

    const diensten = {
        management: {
            title: 'Project\u00ADmanagement',
            description:
                'We begeleiden je sportproject, -evenement of -initiatief van A tot Z. Denk hierbij aan de planning, uitvoering, communicatie en de verbinding van interne en externe stakeholders.',
            order: 1,
        },
        begeleiding: {
            title: 'Proces\u00ADbegeleiding',
            description:
                'Met onze kennis van sport en verenigen, helpen we je om je processen op de rit te krijgen. Dit doen we in de rol van adviseur, begeleider of projectleider.',
            order: 2,
        },
        ondersteuning: {
            title: 'Verenigings\u00ADondersteuning',
            description:
                'We ondersteunen je sportvereniging op organisatorisch en bestuurlijk gebied. Of het nu gaat om het vinden van vrijwilligers, de omgang met ouders of het maken van een impact.',
            order: 3,
        },
        beheer: {
            title: 'Beheer sport\u00ADaccommodaties',
            description:
                'We denken met je mee over het onderhoud van je binnensportaccommodatie. We behandelen samen één thema of denken met je mee over het volledige beheer.',
            order: 4,
        },
        beleid: {
            title: 'Sportbeleid',
            description:
                'We denken met je mee over de vormgeving van je sportbeleid. Samen zorgen we ervoor dat je weet waar je voor staat (visie), wat de kernopdracht is (missie) en hoe je die verwezenlijkt (strategie).',
            order: 5,
        },
        programma: {
            title: 'Coördinatie buurt\u00ADsportcoach regeling',
            description:
                'We zorgen dat jij je doelen met betrekking tot buurtsportcoachprojecten bereikt. We coördineren de activiteiten, sturen de buurtsportteams aan en zorgen voor afstemming met andere partijen.',
            order: 6,
        },
        organisatie: {
            title: 'Organisatie sport- en beweegprojecten',
            description:
                'We organiseren en coördineren sport- en beweegprojecten. Zo zetten we sport en bewegen in als middel om sociale en maatschappelijke vraagstukken te beantwoorden.',
            order: 7,
        },
        anders: {
            title: 'Iets anders?',
            description:
                'Wil je een of meer van deze diensten combineren of kunnen we iets anders voor je doen? Neem gerust contact op en dan zoeken we samen naar een passende oplossing.',
            order: 8,
        },
    };

    return (
        <Container id="diensten">
            <H2 paddingDesktop="0 0 16px 0" paddingMobile="8px 0">
                Wat is jouw uitdaging?
            </H2>
            <Description marginMobile="0 0 24px 0" marginDesktop="0 0 40px 0">
                Wil je sport of bewegen inzetten om je doelen te behalen? Het
                Sportpunt ondersteunt sportorganisaties, gemeentes en bedrijven
                met advies op maat en persoonlijke begeleiding. Hiervoor werken
                we samen met allerlei sportieve partners. Dit kunnen we voor je
                doen:
            </Description>
            <MobileOnly>
                <DienstenContainer>
                    <StylesProvider injectFirst>
                        <FormControlDiensten variant="outlined">
                            <SelectDiensten
                                labelId="select-diensten"
                                id="demo-simple-select-outlined"
                                value={activeProject}
                                onChange={handleChange}
                                IconComponent={ExpandMoreIcon}
                            >
                                {Object.keys(diensten)
                                    .sort((a, b) => {
                                        const objectA = diensten[a];
                                        const objectB = diensten[b];
                                        return objectA.order > objectB.order
                                            ? 1
                                            : -1;
                                    })
                                    .map((key) => {
                                        const dienst = diensten[key];
                                        return (
                                            <MenuItemDiensten
                                                key={key}
                                                value={key}
                                            >
                                                {dienst.title}
                                            </MenuItemDiensten>
                                        );
                                    })}
                            </SelectDiensten>
                        </FormControlDiensten>
                    </StylesProvider>
                    <DienstenItem value={activeProject}>
                        <H4 marginMobile="0 0 16px 0">
                            {diensten[activeProject].title}
                        </H4>
                        <Description>
                            {diensten[activeProject].description}
                        </Description>
                    </DienstenItem>
                </DienstenContainer>
            </MobileOnly>
            <DesktopOnly>
                <DienstenContainer>
                    {Object.keys(diensten)
                        .sort((a, b) => {
                            const objectA = diensten[a];
                            const objectB = diensten[b];

                            return objectA.order > objectB.order ? 1 : -1;
                        })
                        .map((key) => {
                            const dienst = diensten[key];
                            return (
                                <DienstenItem value={key} key={key}>
                                    <H4 marginMobile="0 0 16px 0">
                                        {dienst.title}
                                    </H4>
                                    <Description>
                                        {dienst.description}
                                    </Description>
                                </DienstenItem>
                            );
                        })}
                    <div></div>
                    <div></div>
                    <div></div>
                </DienstenContainer>
            </DesktopOnly>
        </Container>
    );
};

const FormControlDiensten = styled(FormControl)`
    width: 100%;
    margin-bottom: 16px;

    & .MuiOutlinedInput-notchedOutline {
        border-color: ${(props) => props.theme.colors.black2};
        border-radius: 0;
    }
`;

const MenuItemDiensten = styled(MenuItem)`
    width: 100%;
`;

const DienstenContainer = styled.div`
    @media screen and (min-width: ${(props) => props.theme.devices.desktop}) {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        & [class*='diensten__DienstenItem'] {
            height: 325px;
        }
        & [class*='diensten__DienstenItem'],
        [class*='diensten__DienstenItem'] ~ div {
            flex-basis: 325px;
        }
    }
`;

const SelectDiensten = styled(Select)`
    font-family: ${(props) => props.theme.fontFamily.secondary};
    color: ${(props) => props.theme.colors.black2};
    font-weight: ${(props) => props.theme.fontWeights.bold};

    & .MuiSelect-iconOpen {
        transform: unset;
    }

    & .MuiSelect-iconOutlined {
        width: 36px;
        height: 36px;
        top: calc(50% - 18px);
        color: ${(props) => props.theme.colors.black2};
    }
`;

const DienstenItem = styled.div<DienstenItemProps>`
    background-color: ${(props) => props.theme.colors.lightGrey};
    padding: 32px 24px;

    & h4 {
        hyphens: manual;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
    }
    @media screen and (min-width: 341px) {
        & h4 {
            -webkit-line-clamp: 2;
        }
    }
    @media screen and (min-width: ${(props) => props.theme.devices.desktop}) {
        width: calc(25% - 24px);
        margin-bottom: 32px;
        padding: 32px 32px 48px 32px;

        & h4 {
            min-height: 64px;
        }
    }
`;

export default Diensten;
